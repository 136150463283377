import React from "react";
import { Link, graphql } from "gatsby";

// Layouts
import Default from '../../layouts/default';
import Seo from '../../layouts/seo';

// Components
import PageHeader from "../../components/page-header";
import Section from "../../components/section";
import ArticleCard from "../../components/article-card";

// Assets
import TagImage from '../../assets/images/content/collecting.svg';

// Styles
import '../../sass/layouts/tag-listing.scss';

const Tags = (props) =>  {
  const { tag } = props.pageContext;
  const { totalCount } = props.data.allMarkdownRemark;
  const tagHeader = `${totalCount} post${
    totalCount === 1 ? "" : "s"
  } tagged with “${tag}”`;
  
  const posts = props.data.allMarkdownRemark.edges;
    
  return (
    <Default>
      <Seo title={`Tag: ${tag}`} />

      <PageHeader
        heading={tagHeader}
        imageSource={TagImage}
        description={`Viewing articles tagged with '${tag}'`}
        breadcrumbs={[
          {name: 'Home', url: '/'},
          {name: 'Articles', url: '/articles'},
          {name: 'Tags', url: '/articles/tags'},
        ]}
      />
      
      <Section
        label='Tag listing'
        variants={['padded']}
      >
        <ul className='article-list tag-listing__cards'>
          {posts.map(({ node }, index) => {
            if (node.frontmatter.unpublished) return null;

            return (
              <ArticleCard
                date={node.frontmatter.date}
                title={node.frontmatter.title}
                description={node.frontmatter.description}
                slug={node.frontmatter.path}
                author={node.frontmatter.author}
                tags={node.frontmatter.tags}
                key={index}
              />
            )
          })}
        </ul>

        <Link className='button tag-listing__link' to='/articles/tags'>View all tags</Link>
      </Section>
    </Default>
  );
}

export default Tags;

export const tagPageQuery = graphql`
  query TagPage($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {frontmatter: {templateKey: {eq: "blog-post"}, tags: {in: [$tag]}}}
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            path
            date(formatString: "MMMM DD, YYYY")
            description
            author
            tags
            templateKey
          }
        }
      }
    }
  }
`;
